<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="事件名称">
            <el-input  v-model="searchForm.name" placeholder="请输入 事件名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="事件类型">
            <el-select clearable v-model="searchForm.type" placeholder="请选择 事件类型" size="large">
              <el-option v-for="item in warnEventList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开始时间">
            <el-date-picker v-model="searchForm.startTime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" type="datetime" placeholder="选择开始时间"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="结束时间">
            <el-date-picker v-model="searchForm.endTime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" type="datetime" placeholder="选择结束时间"></el-date-picker>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <el-dialog append-to-body v-model="imgVisible" title="查看图片" width="30%">
      <el-image style="display: block; margin: 0 auto; width: 500px; height: 200px" :src="imageUrl" :preview-src-list="[imageUrl]"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { listData, getCodeInfoByType, getPictureUrl } from "../../api/eventmanage/eventlist";
import {onMounted, reactive, toRefs} from "vue";
import Searchbar from '@/components/Searchbar';
import {ElMessage} from "element-plus";
export default {
  components:{
    Searchbar
  },
  setup(){
    let state = reactive({
      imgVisible:false,
      imageUrl: "",
      searchForm:{},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      warnEventList:[],
      tableData:[],
      attrList: [
        {
          label: "事件名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "事件类型",
          prop: "typeName",
          render: (row) => <div>{row.typeName}</div>
        },
        {
          label: "创建时间",
          prop: "createTime",
          render: (row) => <div>{row.createTime}</div>
        },
        {
          label: "告警图片",
          prop: "url",
          render: (row) => {
            if(row.url){
              if(row.typeName == '平台事件'){
                return <el-button type="text" onClick={()=>showImage(row.purl)}>查看图片</el-button>
              }else{
                return <el-image style="width: 100px; height: 100px" src={row.url} preview-src-list={[row.url]} fit="cover" />
              }

            }
          }
        },
        {
          label: "经纬度",
          prop: "location",
          render: (row) => <div>{row.location}</div>
        },
        {
          label: "所属区域名称",
          prop: "regionName",
          render: (row) => <div>{ row.regionName }</div>
        },
      ]
    })

    function showImage(url){
      getPictureUrl({ url }).then(res=>{
        state.imgVisible = true;
        state.imageUrl = res.data.data;
      })
    }

    function onLoad(){
      listData({ page:state.page.current, limit:state.page.limit, ...state.searchForm }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function resetForm() {
      state.page.current = 1;
      state.page.limit = 10;
      state.searchForm = {};
      onLoad();
    }

    function onSearch(){
      state.page.current = 1;
      state.page.limit = 10;

      if(state.searchForm.startTime && state.searchForm.endTime){
        if(new Date(state.searchForm.startTime).getTime() > new Date(state.searchForm.endTime).getTime()) return ElMessage({message: `开始时间不能大于结束时间`, type: 'error'});
      }

      onLoad();
    }

    onMounted(()=>{
      getCodeInfoByType({
        type: 'warnEventType'
      }).then(res=>{
        state.warnEventList = res.data.data;
      });
      onLoad()
    })

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad();
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad();
    }

    return{
      onSearch,
      resetForm,
      handleCurrentChange,
      handleSizeChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>